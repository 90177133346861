import { ApiUser, ROLE_ADMIN } from './getUser'

type AffiliateUser = Pick<ApiUser, 'affiliate_user_id' | 'role_id'>;

export function mustHaveMFA(user: AffiliateUser) {
    if (process.env.REACT_APP_DISABLE_MFA === 'true') {
        return false
    }
    return  user.affiliate_user_id !== undefined && Number(user.role_id) === ROLE_ADMIN
}
